import styled from 'styled-components';

const Content = styled.div`
	position: relative;
	margin: 32px 0 0 0;
	transform: scale(0.8);
	transform-origin: 0 0;
`;

const JayMo = styled.a`
	position: absolute;
	top: 0;
	left: 0;
	width: 86px;
	outline: none;

	img {
		width: 100%;
	}
`;

const Junho = styled.a`
	position: absolute;
	top: 0;
	left: 116px;
	width: 96px;
	outline: none;

	img {
		width: 100%;
	}
`;
const YJ = styled.a`
	position: absolute;
	top: 35px;
	left: 24px;
	width: 94px;
	outline: none;

	img {
		width: 100%;
	}
`;

const Steve = styled.a`
	position: absolute;
	top: 0px;
	left: 240px;
	width: 96px;
	outline: none;

	img {
		width: 100%;
	}
`;

const Daniel = styled.a`
	position: absolute;
	top: 45px;
	left: 135px;
	width: 120px;
	outline: none;

	img {
		width: 100%;
	}
`;

function Signatures() {
	return (
		<Content>
			<JayMo href="https://jaymo.io" target="_blank">
				<img src="/signature-jaymo.png" />
			</JayMo>
			<Junho href="https://github.com/junhoyeo" target="_blank">
				<img src="/signature-junho.png" />
			</Junho>
			<YJ
				href="https://www.facebook.com/profile.php?id=100004210565613"
				target="_blank"
			>
				<img src="/signature-yj.png" />
			</YJ>
			<Steve href="https://www.linkedin.com/in/iam-stevejkang/" target="_blank">
				<img src="/signature-steve.png" />
			</Steve>
			<Daniel href="https://unifiedh.com/" target="_blank">
				<img src="/signature-daniel.png" />
			</Daniel>
		</Content>
	);
}

export default Signatures;
