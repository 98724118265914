import styled from 'styled-components';

const CenterSpacer = styled.div`
	width: calc(520px / 2);
	height: calc(320px / 2);
	/*background: green;*/
	margin: 24px 0;
`;

export default CenterSpacer;
