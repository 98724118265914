import styled from 'styled-components';
import { motion } from 'framer-motion';

const Words = styled(motion.textarea)`
	width: 100%;
	height: 108px;
	max-height: 224px;
	padding: 16px 24px;

	font-size: 24px;
	line-height: 38px;

	border: none;
	outline: none;
	resize: none;
	text-align: center;
	color: rgba(0, 0, 0, 0.66);
	background: none;

	@media (max-width: 720px) {
		font-size: 18px;
		line-height: 28px;
	}
`;

export default Words;
