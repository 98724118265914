import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { stylesNoSelect } from 'styles';

const Overlay = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999999;
	width: 100vw;
	height: 100vh;
	height: -webkit-fill-available;
	background: radial-gradient(
		50% 50% at 50% 50%,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.75) 100%
	);
	pointer-events: none;
	${stylesNoSelect}
`;

const OverlayNW = styled(motion.div)`
	position: absolute;
	top: 40px;
	left: 40px;
	margin: 0;

	@media (max-width: 720px) {
		top: 24px;
		left: 24px;
	}
`;

const OverlayNE = styled(motion.div)`
	position: absolute;
	top: 40px;
	right: 40px;
	margin: 0;

	display: flex;
	flex-direction: column;
	align-items: flex-end;

	@media (max-width: 720px) {
		top: 24px;
		right: 24px;
	}
`;

const OverlaySE = styled(motion.div)`
	position: absolute;
	bottom: 40px;
	right: 40px;
	margin: 0;

	display: flex;
	flex-direction: column;
	align-items: flex-end;

	@media (max-width: 720px) {
		bottom: 24px;
		left: 24px;
	}
`;

const OverlaySW = styled(motion.div)`
	position: absolute;
	bottom: 40px;
	left: 40px;
	margin: 0;

	@media (max-width: 720px) {
		bottom: 24px;
		left: 24px;
	}
`;

const OverlayText = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 700;

  @media (max-width: 720px) {
  	font-size: 18px;
  	font-weight: 18px;
  }

  @media (max-width: 480px) {
  	font-size: 16px;
  	font-weight: 16px;
  }

  ${props =>
		props.marginDirection == 'top' &&
		css`
			margin: -2px 0 0 0;
		`}

  ${props =>
		props.marginDirection == 'bottom' &&
		css`
			margin: 0 0 -2px 0;
		`}

	${props =>
		props.paddingDirection == 'left' &&
		css`
			padding: 0 0 0 24px;
		`}

	${props =>
		props.paddingDirection == 'right' &&
		css`
			padding: 0 24px 0 0;
		`}
`;

export { Overlay, OverlayNW, OverlayNE, OverlaySW, OverlaySE, OverlayText };
