import styled from 'styled-components';
import { motion } from 'framer-motion';

const InvitationForm = styled(motion.div)`
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*background: rgba(255, 200, 200, 1);*/

	background: linear-gradient(180deg, #eceafc 0%, #dddddd 47.4%, #d1d1d1 100%);
	border-radius: 20px;
	/*transform: rotateY(180deg);*/
	transform-style: preserve-3d;
	backface-visibility: hidden;

	pointer-events: none;

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 10%;

	h1 {
		margin: 0;
		color: rgba(0, 0, 0, 0.33);
		font-weight: 400;
	}
`;

export default InvitationForm;
