import styled from 'styled-components';

const Text = styled.h1`
	margin: 0;
	font-size: 24px;
	line-height: 32px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.33);

	span {
		display: block;
		font-weight: 700;
		color: rgba(0, 0, 0, 0.5);
	}
`;

function InvitationFormText() {
	return (
		<Text>
			주변의 시선을 신경쓰지 않는다면, <span>무엇을 하고 싶나요?</span>
		</Text>
	);
}

export default InvitationFormText;
