import styled from 'styled-components';

const Nickname = styled.input`
	max-width: 50%;
	flex: 1;
	height: 48px;
	margin: 0 16px 0 0;
	padding: 0 16px;
	font-size: 16px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 16px;
	background: transparent;
	border-radius: 12px;
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
`;

export default Nickname;
