import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

import Wrapper from './Wrapper';
import { H1, P } from 'components/Typography';
import Container from './Container';
import Invitation from './Invitation';
import Signatures from './Signatures';

const ScrollContainer = styled(motion.div)`
  @media (max-width: 1350px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
  }
`;

function Manifesto({ setIsRenderingWall }) {
  const [hasOpened, setHasOpened] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);
  const animateWrapper = useAnimation();
  const animateManifesto = useAnimation();
  const animateContent = useAnimation();
  const refWrapper = useRef();

  useEffect(() => {
    animateWrapper.start({ opacity: 1, background: 'rgba(126,118,119,1)' });
    if (refWrapper && refWrapper.current) {
      // refWrapper.current.scrollTo(1000, 1000);
    }
  }, []);

  useEffect(() => {
    if (hasOpened) {
      // animateWrapper.start({ background: "rgba(126,118,119,0)", transition: { duration: 0.66 } });
      animateManifesto.start({ opacity: 0, transition: { duration: 0.66 } });
      setTimeout(() => {
        animateContent.start({ opacity: 0, transition: { duration: 0.66 } });
      }, 2400);

      setTimeout(() => {
        setIsRenderingWall(true);
      }, 2500);
    }
  }, [hasOpened]);

  return (
    <ScrollContainer
      initial={{ opacity: 0, background: 'rgba(126,118,119,0)' }}
      animate={animateWrapper}
      exit={{ opacity: 0, background: 'rgba(126,118,119,0)' }}
      transition={{ duration: 0.66 }}
    >
      <Wrapper
        ref={refWrapper}
        hasClicked={hasClicked}
        animate={animateContent}
        // animate={{ opacity: 1 }}
      >
        <Container animate={animateManifesto}>
          <H1>loooo 는 진짜 앱이 아닙니다.</H1>
          <P>
            만우절 장난에서 시작된 거라고 이미 많은 분들이 예상하셨을지도
            모르겠습니다. 네, 이제 여러분의 관심을 끌었으니 우리가 왜 이런 일을
            했는지에 대한 이야기를 들려 드리겠습니다.
          </P>
          <P>
            우리는 프로덕트를 만드는 사람들입니다. 몇 명은 대학생이고, 일부는
            스타트업에서 일하고, 어떤 사람은 미성년자이기도 합니다.
          </P>
          <P>
            우리는 자라면서 비슷한 경험을 겪었고, 비슷한 점을 깨달았습니다.
            우리의 주변 사람들은 마음이 시키는 일을 하기 위해 노력했습니다.
            하지만 주변 시선이나 두려움 때문에 마음속 깊이 하고 싶었던 일보다,
            주변에서 당연하게 생각하는 선택지로 결정하는 것을 자주 보았습니다.
          </P>
          <P>
            우리는 ‘사회적 피드백 ’이 종종 우리의 마음이 진정으로 원하는 것을
            거스르라고 말하는 사회에 살고 있습니다. 우리는 이것이 불행하다고
            생각합니다. 똑똑한 사람들이 모여서 그들이 진정으로 믿는 것을 할 때,
            기발한 일들이 일어난다고 믿기 때문입니다.
          </P>
          <P>
            업계 전반에 걸쳐 창업을 시작하거나, 새로운 모험을 위해 학교를
            중퇴하거나, 코딩 작업을 시작할 수 있도록 영감을 주기 위해, 우리는 이
            사이트를 만들어 여러분께 제안합니다.
          </P>
          <P>
            아래에서, 여러분은 새로운 모험에 대한 내면의 욕망에 대해 사람들의
            가장 솔직한 이야기를 볼 수 있습니다. 이곳에서 함께 하시길 바랍니다.
            이 프로젝트 역시 우리에게 새로운 모험의 시작이며, 이 시도가 다른
            이들의 마음을 움직일 수 있기를 바랍니다.
          </P>
        </Container>
        <Invitation setHasClicked={setHasClicked} setHasOpened={setHasOpened} />
        <P
          style={{
            position: 'absolute',
            right: 16,
            bottom: 16,
            // transform: 'translateX(-50%)',
            background: 'rgba(0, 0, 0, 0)',
            borderRadius: 8,
            padding: '4px 8px 2px 8px',
            maxWidth: 'calc(100vw - 32px)',
            textAlign: 'right',
            fontSize: 14,
          }}
        >
          초대장 공유시 개인 식별을 위해 사용된 핸드폰 번호는 모두 즉시
          파기되었음을 알려드립니다.
          <br />—{' '}
          <a
            href="mailto:ooool@loooo.app"
            style={{
              textDecoration: 'none',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            ooool@loooo.app
          </a>
        </P>
      </Wrapper>
    </ScrollContainer>
  );
}

export default Manifesto;
