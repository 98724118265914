import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const PostCard = styled(motion.div)`
  /* position: absolute; */
  /*width: calc(100vw - 32px);
  max-width: 376px;*/
  max-width: 376px;
  height: auto;
  /*flex: 0 376px;*/
  /*margin: 32px 16px;*/
  margin: 24px;
  padding: 20px 28px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;

  @media (max-width: 720px) {
  	margin: 16px;
  	padding: 16px 20px;
  	border-radius: 14px;
  }

  ${props =>
		props.colorBase == 0 &&
		css`
			background: linear-gradient(
					180deg,
					rgba(171, 239, 223, 0.2) 0%,
					rgba(197, 248, 226, 0.2) 0.01%,
					rgba(180, 245, 245, 0.2) 100%
				),
				rgba(255, 255, 255, 0.8);
		`}

  ${props =>
		props.colorBase == 1 &&
		css`
			background: linear-gradient(
					180deg,
					rgba(171, 239, 223, 0.2) 0%,
					rgba(255, 226, 226, 0.2) 0.01%,
					rgba(255, 222, 199, 0.2) 100%
				),
				rgba(255, 255, 255, 0.8);
		`}
	
	${props =>
		props.colorBase == 2 &&
		css`
			background: linear-gradient(
					180deg,
					rgba(252, 240, 199, 0.2) 0%,
					rgba(255, 250, 203, 0.2) 0.01%,
					rgba(237, 255, 186, 0.2) 100%
				),
				rgba(255, 255, 255, 0.8);
		`}
	
	${props =>
		props.colorBase == 3 &&
		css`
			background: linear-gradient(
					180deg,
					rgba(234, 171, 239, 0.2) 0%,
					rgba(244, 234, 255, 0.2) 0.01%,
					rgba(232, 203, 255, 0.2) 100%
				),
				rgba(255, 255, 255, 0.8);
		`}
`;

export default PostCard;
