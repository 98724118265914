import styled from 'styled-components';

const PostCardNickname = styled.h4`
	margin: 0;
	padding: 0;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.33);
	opacity: 1;
`;

export default PostCardNickname;
