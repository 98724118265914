import styled from 'styled-components';
import { motion } from 'framer-motion';

const TemplateBG = `
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
`;

const CardFormBG1 = styled(motion.div)`
	${TemplateBG}
	background: linear-gradient(180deg, #abefdf 0%, #c5f8e2 0.01%, #b4f5f5 100%);
`;

const CardFormBG2 = styled(motion.div)`
	${TemplateBG}
	background: linear-gradient(180deg, #eaabef 0%, #f4eaff 0.01%, #e8cbff 100%);
`;

const CardFormBG3 = styled(motion.div)`
	${TemplateBG}
	background: linear-gradient(180deg, #fcf0c7 0%, #fffacb 0.01%, #edffba 100%);
`;

const CardFormBG4 = styled(motion.div)`
	${TemplateBG}
	background: linear-gradient(180deg, #abefdf 0%, #ffe2e2 0.01%, #ffdec7 100%);
`;

export { CardFormBG1, CardFormBG2, CardFormBG3, CardFormBG4 };
