import styled from 'styled-components';

const AfterContentText = styled.p`
	width: 100%;
	padding: 16px 24px;

	font-size: 24px;
	line-height: 38px;

	border: none;
	outline: none;
	resize: none;
	text-align: center;
	color: rgba(0, 0, 0, 0.66);

	white-space: pre-line;
	overflow-wrap: anywhere;

	@media (max-width: 720px) {
		font-size: 18px;
		line-height: 28px;
	}
`;

export default AfterContentText;
