import styled from 'styled-components';
import { transitionTween } from 'styles';

const AfterContentButton = styled.button`
	position: absolute;
	bottom: 16px;
	right: 16px;

	width: 96px;
	height: 40px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.66);
	color: #ffffff;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;

	&:hover {
		background: rgba(0, 0, 0, 0.8);
	}

	${transitionTween}
`;

export default AfterContentButton;
