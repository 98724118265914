import styled from 'styled-components';
import { transitionTween } from 'styles';

const FormDetails = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 16px 16px;
	display: flex;
	flex-direction: row;
	opacity: ${props => (props.isActive ? 1 : 0)};
	pointer-events: ${props => (props.isActive ? 'auto' : 'none')};
	${transitionTween}
`;

export default FormDetails;
