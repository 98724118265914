import styled from 'styled-components';
import { motion } from 'framer-motion';

const WallCanvasBackground = styled(motion.div)`
	position: fixed;
	z-index: 0;
	width: 100vw;
	height: 100vh;

	/*background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
		conic-gradient(
			from 180deg at 50% 50%,
			#e1bbff 0deg,
			#bbfbeb 46.87deg,
			#edffba 105deg,
			#f8fcc7 165deg,
			#ffd1c7 230.62deg,
			#ffc1fd 286.87deg,
			#e1bbff 360deg
		);
	filter: blur(80px);*/

	background: url(/background-beachball.jpg) no-repeat center center;
	background-size: cover;
	/*opacity: 0.5;*/
`;

export default WallCanvasBackground;
