import { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

import Wrapper from './Wrapper';
import ContainerInvitation from './ContainerInvitation';
import InvitationCard from './InvitationCard';
import InvitationOpen from './InvitationOpen';
import InvitationForm from './InvitationForm';
import InvitationFormText from './InvitationFormText';
import { H1 } from 'components/Typography';

function Invitation({ setHasOpened }) {
  const [isHovering, setIsHovering] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);

  useEffect(() => setHasClicked(hasClicked), [hasClicked]);

  const refInvitation = useRef();

  const animateInvitation = useAnimation();
  const animateCard = useAnimation();
  const animateButton = useAnimation();
  const animateForm = useAnimation();
  const animateFormText = useAnimation();

  const onMouseEnter = () => {
    if (!hasClicked) {
      animateCard.start({
        rotateX: 10,
        rotateY: -30,
        // rotateY: -180,
        // opacity: 0,
        transition: { duration: 0.66 },
      });
      animateForm.start({
        rotateX: -170,
        rotateY: -150,
        // rotateY: 0,
        // opacity: 1,
        transition: { duration: 0.66 },
      });
      animateInvitation.start({ scale: 1.2, transition: { duration: 0.66 } });
    }
  };

  const onMouseLeave = () => {
    if (!hasClicked) {
      animateCard.start({
        scale: 1,
        rotateX: 0,
        rotateY: 0,
        // opacity: 1,
        transition: { duration: 0.66 },
      });
      animateForm.start({
        rotateX: -180,
        rotateY: -180,
        // opacity: 0,
        transition: { duration: 0.66 },
      });
      animateInvitation.start({ scale: 1, transition: { duration: 0.66 } });
    }
  };

  const onMouseClick = () => {
    if (!hasClicked) {
      animateCard.start({
        rotateX: 180,
        rotateY: -180,
        opacity: 0,
        transition: { duration: 0.66 },
      });
      animateForm.start({
        rotateX: 0,
        rotateY: 0,
        opacity: 1,
        transition: { duration: 0.66 },
      });

      animateFormText.start({
        opacity: 1,
        transition: { duration: 0.66 },
      });

      //     animateInvitation.start({
      //
      //     });
      let boundingBox =
        refInvitation && refInvitation.current
          ? refInvitation.current.getBoundingClientRect()
          : { x: 0, y: 0 };

      let deltaX =
        window.innerWidth / 2 - boundingBox.x - boundingBox.width / 2;

      let animationObject = {
        x: deltaX,
        scale: 1,
        transition: { duration: 0.66 },
      };

      if (window.innerWidth <= 1350) {
        let deltaY =
          window.innerHeight / 2 - boundingBox.y - boundingBox.height / 2;
        animationObject['y'] = deltaY;
      }

      animateInvitation.start(animationObject);

      setHasClicked(true);
      setHasOpened(true);
    }
  };

  return (
    <Wrapper>
      <ContainerInvitation
        animate={animateInvitation}
        ref={refInvitation}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onMouseClick}
      >
        <InvitationCard animate={animateCard}>
          <svg
            width="520"
            height="320"
            viewBox="0 0 520 320"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="520" height="320" fill="#BBBBBB" />
            <g opacity="0.4">
              <path
                d="M0.454224 319.999H520L354.5 124H169L0.454224 319.999Z"
                fill="url(#paint0_linear)"
              />
              <path
                d="M0.454224 319.999H520L354.5 124H169L0.454224 319.999Z"
                fill="url(#paint1_linear)"
              />
            </g>
            <g opacity="0.4">
              <path
                d="M0 1L0.00497351 320L162 128.857L0 1Z"
                fill="url(#paint2_linear)"
              />
              <path
                d="M0 1L0.00497351 320L162 128.857L0 1Z"
                fill="url(#paint3_linear)"
              />
            </g>
            <g opacity="0.4">
              <path
                d="M520 0.09375V320L359 128L520 0.09375Z"
                fill="url(#paint4_linear)"
              />
              <path
                d="M520 0.09375V320L359 128L520 0.09375Z"
                fill="url(#paint5_linear)"
              />
            </g>
            <path
              d="M22.3184 319.805L179.71 134L160.5 130L-0.27124 319.999L22.3184 319.805Z"
              fill="url(#paint6_linear)"
            />
            <path
              d="M503.241 319.417L343.662 134L358.5 126L520 319.999L503.241 319.417Z"
              fill="url(#paint7_linear)"
            />
            <path
              d="M503.241 319.417L343.662 134L358.5 126L520 319.999L503.241 319.417Z"
              fill="url(#paint8_linear)"
            />
            <g filter="url(#filter0_d)">
              <path
                d="M145.46 123.153L-0.276366 0.987942L-1.00195 0H520L289.01 182.504C257.495 199.7 233.632 185.472 233.632 185.472C221.241 183.492 145.46 123.153 145.46 123.153Z"
                fill="url(#paint9_linear)"
              />
            </g>
            <defs>
              <filter
                id="filter0_d"
                x="-31.002"
                y="-20"
                width="581.002"
                height="251"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="10" />
                <feGaussianBlur stdDeviation="15" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear"
                x1="260.228"
                y1="140.333"
                x2="260.228"
                y2="319.998"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.3171" stopColor="white" />
                <stop offset="0.8293" stopColor="#EFF0F1" />
                <stop offset="0.9878" stopColor="#EAABEF" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="260.227"
                y1="140.332"
                x2="260.227"
                y2="319.999"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.0001" stopColor="#A7A7A7" />
                <stop offset="1" stopColor="#A3A3A3" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="0.00223805"
                y1="160.5"
                x2="149.991"
                y2="160.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#EFCCAB" />
                <stop offset="0.0854" stopColor="#F0F1F2" />
                <stop offset="0.5" stopColor="white" />
                <stop offset="1" stopColor="#F2F2F2" />
              </linearGradient>
              <linearGradient
                id="paint3_linear"
                x1="0"
                y1="144"
                x2="171"
                y2="144"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#A9A9A9" />
                <stop offset="1" stopColor="#B7B7B7" />
              </linearGradient>
              <linearGradient
                id="paint4_linear"
                x1="369.894"
                y1="160.046"
                x2="520.001"
                y2="160.046"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#EFF0F1" />
                <stop offset="0.1933" stopColor="#FAFBFB" />
                <stop offset="0.3902" stopColor="white" />
                <stop offset="0.8293" stopColor="#EFF0F1" />
                <stop offset="0.9878" stopColor="#FFD1CB" />
              </linearGradient>
              <linearGradient
                id="paint5_linear"
                x1="520"
                y1="128"
                x2="351.5"
                y2="128"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#A8A8A8" />
                <stop offset="1" stopColor="#AEAEAE" />
              </linearGradient>
              <linearGradient
                id="paint6_linear"
                x1="89.7187"
                y1="134.001"
                x2="89.7187"
                y2="320"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.364583" stopColor="#BBBBBB" />
                <stop offset="1" stopColor="#B2B2B2" />
              </linearGradient>
              <linearGradient
                id="paint7_linear"
                x1="431.831"
                y1="130.086"
                x2="431.831"
                y2="320"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.364583" stopColor="#BBBBBB" />
                <stop offset="1" stopColor="#A3A3A3" />
              </linearGradient>
              <linearGradient
                id="paint8_linear"
                x1="431.831"
                y1="130.086"
                x2="431.831"
                y2="320"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.364583" stopColor="#BBBBBB" />
                <stop offset="1" stopColor="#B2B2B2" />
              </linearGradient>
              <linearGradient
                id="paint9_linear"
                x1="259.499"
                y1="0"
                x2="259.499"
                y2="191"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#ECEAFC" />
                <stop offset="0.473958" stopColor="#DDDDDD" />
                <stop offset="1" stopColor="#D1D1D1" />
              </linearGradient>
            </defs>
          </svg>
          <InvitationOpen
            // initial={{ x: '-50%', scale: 1 }}
            initial={{ x: '-50%', scale: 1 }}
            animate={animateButton}
          >
            초대장 열기
          </InvitationOpen>
        </InvitationCard>
        <InvitationForm
          animate={animateForm}
          // initial={{ rotateX: -180, rotateY: -180, opacity: 1 }}
          initial={{ rotateX: -180, rotateY: -180, opacity: 0 }}
        >
          <motion.div animate={animateFormText} initial={{ opacity: 0 }}>
            <InvitationFormText />
          </motion.div>
        </InvitationForm>
      </ContainerInvitation>
    </Wrapper>
  );
}

export default Invitation;
