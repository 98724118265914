import styled from 'styled-components';
import { motion } from 'framer-motion';

const AfterContent = styled(motion.div)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export default AfterContent;
