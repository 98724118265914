import styled from 'styled-components';

const PostCardWords = styled.p`
	margin: 0 0 16px 0;
	padding: 0;
	font-size: 16px;
	line-height: 29px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.66);
	opacity: 1;

	@media (max-width: 720px) {
		margin: 0 0 8px 0;
	}
`;

export default PostCardWords;
