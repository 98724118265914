import styled from 'styled-components';
import { motion } from 'framer-motion';

const WordsGhost = styled(motion.div)`
	position: absolute;
	top: 0;

	max-width: 100%;
	padding: 16px 24px;
	font-size: 24px;
	line-height: 38px;
	/*height: 38px;*/
	min-height: 108px;
	border: none;
	outline: none;
	resize: none;
	text-align: center;
	white-space: pre-line;
	overflow-wrap: anywhere;
	opacity: 0;

	@media (max-width: 720px) {
		font-size: 18px;
		line-height: 28px;
	}
`;

export default WordsGhost;
