import styled from 'styled-components';
import { motion } from 'framer-motion';

const Matrix = styled(motion.div)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /*width: 200vw;
  height: 200vh;*/

  /*width: 2880px;
  height: 1620px;*/

  width: ${props => props.canvasWidth};
  height: ${props => props.canvasHeight};
  transform: scale(${props => props.scaleFactor ?? 1});

  /*top: calc(50% - (1620px / 2));
  left: calc(50% - (2880px / 2));*/
  /*transform: translateX(-50%) translateY(-50%);
  transform-origin: 50% 50%;*/

  /*display: none;*/
`;

export default Matrix;
