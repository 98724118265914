import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled(motion.div)`
	max-width: 536px;
	width: 100%;
	flex: 0 536px;

	@media (max-width: 1350px) {
		flex: 0 auto;
		margin: 72px 0 0 0;
		padding: 0 0 128px 0;
	}

	@media (max-width: 720px) {
		flex: 0 auto;
		margin: 48px 0 0 0;
	}

	@media (max-width: 480px) {
		flex: 0 auto;
		margin: 0 0 0 0;
	}
`;

export default Container;
