import styled from 'styled-components';
import { motion } from 'framer-motion';

const ContainerInvitation = styled(motion.div)`
	position: relative;

	max-width: 520px;
	height: 320px;
	/*display: inline-flex;
	align-items: center;
	justify-content: center;*/
	flex: 1;

	border-radius: 20px;

	/*border: 1px solid red;*/
	/*transform: translate3d(0, 0, 0);*/
	/*background: #ffffff;*/
	perspective: 1000px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	/*border: 1px solid red;*/

	cursor: pointer;
`;

export default ContainerInvitation;
