import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import {
	Overlay,
	OverlayNW,
	OverlayNE,
	OverlaySW,
	OverlaySE,
	OverlayText,
} from './Overlay';

const Compass = forwardRef((props, ref) => {
	const [isRendering, setIsRenderingState] = useState(false);

	useImperativeHandle(ref, () => ({
		setIsRendering(value) {
			// console.log('child function called with', value);
			setIsRenderingState(value);
		},
	}));

	return (
		<AnimatePresence>
			{isRendering && (
				<Overlay
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ type: 'tween', duration: 0.175 }}
				>
					<OverlayNW
						initial={{ opacity: 0, x: -72, y: -72 }}
						animate={{ opacity: 1, x: 0, y: 0 }}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							fill="none"
							stroke="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							className="feather feather-arrow-up-left"
							viewBox="0 0 24 24"
						>
							<path d="M17 17L7 7" />
							<path d="M7 17L7 7 17 7" />
						</svg>
						<OverlayText marginDirection="top" paddingDirection="left">
							성격 / 가치관
						</OverlayText>
					</OverlayNW>
					<OverlayNE
						initial={{ opacity: 0, x: 72, y: -72 }}
						animate={{ opacity: 1, x: 0, y: 0 }}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							fill="none"
							stroke="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							className="feather feather-arrow-up-right"
							viewBox="0 0 24 24"
						>
							<path d="M7 17L17 7" />
							<path d="M7 7L17 7 17 17" />
						</svg>
						<OverlayText marginDirection="top" paddingDirection="right">
							대인 / 가족 관계
						</OverlayText>
					</OverlayNE>
					<OverlaySE
						initial={{ opacity: 0, x: 72, y: 72 }}
						animate={{ opacity: 1, x: 0, y: 0 }}
					>
						<OverlayText marginDirection="bottom" paddingDirection="right">
							일상 / 취미
						</OverlayText>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							fill="none"
							stroke="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							className="feather feather-arrow-down-right"
							viewBox="0 0 24 24"
						>
							<path d="M7 7L17 17" />
							<path d="M17 7L17 17 7 17" />
						</svg>
					</OverlaySE>
					<OverlaySW
						initial={{ opacity: 0, x: -72, y: -72 }}
						animate={{ opacity: 1, x: 0, y: 0 }}
					>
						<OverlayText marginDirection="bottom" paddingDirection="left">
							커리어 / 학업
						</OverlayText>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							fill="none"
							stroke="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							className="feather feather-arrow-down-left"
							viewBox="0 0 24 24"
						>
							<path d="M17 7L7 17" />
							<path d="M17 17L7 17 7 7" />
						</svg>
					</OverlaySW>
				</Overlay>
			)}
		</AnimatePresence>
	);
});

export default Compass;
