import PostCard from "./PostCard";
import PostCardWords from "./PostCardWords";
import PostCardNickname from "./PostCardNickname";

function Post({ nickname, words, category, weight }) {
  // const isDragging = colIndex ==== active.col && rowIndex ==== active.row;
  // const diagonalIndex = (360 / 6) * (colIndex + rowIndex);
  // const d = distance(
  // { x: active.col, y: active.row },
  // { x: colIndex, y: rowIndex }
  // );
  // const springConfig = {
  // stiffness: Math.max(700 - d * 120, 0),
  // damping: 20 + d * 5
  // };
  // const dx = useSpring(x, springConfig);
  // const dy = useSpring(y, springConfig);

  let magnitude = parseInt(weight);

  let colorBase = 0;
  if (category == "career") colorBase = 0;
  if (category == "others") colorBase = 1;
  if (category == "self") colorBase = 2;
  if (category == "daily") colorBase = 3;

  return (
    <PostCard
      // initial={{
      //   x: window.innerWidth / 2 + x * (376 + 16),
      //   y: window.innerHeight / 2 + y * 300,
      // }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      colorBase={colorBase}
    >
      <PostCardWords>{words}</PostCardWords>
      <PostCardNickname>— {nickname} —</PostCardNickname>
    </PostCard>
  );
}

export default Post;
