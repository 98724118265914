import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';

import Cursor from 'components/Cursor';
import Manifesto from 'components/Manifesto';
import Form from 'components/Form';
import Compass from 'components/Compass';
import Wall from 'pages/Wall';
import Navigation from 'pages/Navigation';

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: url(/background.jpg) no-repeat center center;
  background-size: cover;
`;

function App() {
  // const [shouldRenderCompass, setShouldRenderCompass] = useState(false);
  const [isRenderingWall, setIsRenderingWall] = useState(false);
  const refCursor = useRef();
  const refNavigation = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let triggerCompass = () => {};

  const toggleTouching = value => {
    // alert('touched:' + value);
    if (refNavigation && refNavigation.current) {
      if (value) {
        triggerCompass = setTimeout(() => {
          refNavigation.current.setIsTouching(value);
        }, 660);
      } else {
        refNavigation.current.setIsTouching(false);
        try {
          clearTimeout(triggerCompass);
        } catch (err) {}
      }
    }
  };

  return (
    <Container>
      {isRenderingWall && <Wall toggleTouching={toggleTouching} />}
      <AnimatePresence>
        {!isRenderingWall && (
          <Manifesto setIsRenderingWall={setIsRenderingWall} />
        )}
      </AnimatePresence>
      <Navigation ref={refNavigation} />
    </Container>
  );
}

export default App;
