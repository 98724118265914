import styled from 'styled-components';
import { motion } from 'framer-motion';

const WallCanvasBackdrop = styled(motion.div)`
	position: fixed;
	z-index: 0;
	width: 100vw;
	height: 100vh;
	background: #ffffff;
`;

export default WallCanvasBackdrop;
