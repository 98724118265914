import {
	useState,
	useEffect,
	useRef,
	useImperativeHandle,
	forwardRef,
} from 'react';
import Cursor from 'components/Cursor';
import Compass from 'components/Compass';
import useMobileDetect from 'use-mobile-detect-hook';
import styled from 'styled-components';

const MobileHandler = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 999999;
	/*pointer-events: none;*/
	opacity: 0;
	background: none;
`;

const Navigation = forwardRef(({ isTouching }, ref) => {
	// const [isDragging, setIsDragging] = useState(false);
	const refCompass = useRef();
	const detectMobile = useMobileDetect();

	useImperativeHandle(ref, () => ({
		setIsTouching(value) {
			// alert('Navigation touched ' + value);
			if (refCompass && refCompass.current) {
				refCompass.current.setIsRendering(value);
			}
		},
	}));

	const setIsDragging = (value, e) => {
		// console.log(
		// 	"setIsDragging on Navigation",
		// 	refCompass,
		// 	refCompass.current
		// 	// refCompass.current.setIsRendering
		// );

		let targetClass = e.target && e.target.className ? e.target.className : '';
		// console.log('targetclass', targetClass);

		if (targetClass.includes && !targetClass.includes('cardForm')) {
			if (refCompass && refCompass.current) {
				refCompass.current.setIsRendering(value);
			}
		}
	};

	return (
		<>
			<Compass ref={refCompass} />
			{/* {detectMobile.isMobile() && ( */}
			{/* 	<MobileHandler */}
			{/* 		onTouchStart={() => setIsDragging(true)} */}
			{/* 		onTouchEnd={() => setIsDragging(false)} */}
			{/* 	/> */}
			{/* )} */}
			{!detectMobile.isMobile() && <Cursor setIsDragging={setIsDragging} />}
		</>
	);
});

export default Navigation;
