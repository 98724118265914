import styled from 'styled-components';
import { motion } from 'framer-motion';

const WallCanvas = styled(motion.div)`
	position: absolute;
	width: 100vw;
	height: 100vh;
	/*background: #7e7677;*/
`;

export default WallCanvas;
