import styled from 'styled-components';

const H1 = styled.h1`
	margin: 0 0 24px 0;
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	color: #ffffff;

	/*@media (max-width: 720px) {
		font-size: 22px;
		line-height: 28px;
		margin: 0 0 20px 0;
	}*/
`;

const P = styled.p`
	margin: 0 0 20px 0;
	font-size: 15px;
	line-height: 27px;
	font-weight: 400;
	color: #ffffff;
	opacity: 0.85;

	&:last-of-type {
		margin: 0;
	}
`;

export { H1, P };
