import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1350px) {
    width: 500px;
    /*border: 1px solid red;*/
    margin: 0 0 16px 0;
    padding: 0;
    /*padding: 48px 0 0 0;*/
    /*padding: 0 0 16px 0;*/
  }

  @media (max-width: 600px) {
    margin: 0;
    transform: scale(0.8);
  }

  @media (max-width: 480px) {
    transform: scale(0.7);
  }

  @media (max-width: 430px) {
    transform: scale(0.6);
  }
`;

export default Wrapper;
