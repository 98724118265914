import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Post from 'components/Post';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { useWindowSize } from 'hooks/useWindowSize';
import useMobileDetect from 'use-mobile-detect-hook';

import Form from 'components/Form';
import WallCanvas from 'components/Wall/WallCanvas';
import WallCanvasBackdrop from 'components/Wall/WallCanvasBackdrop';
import WallCanvasBackground from 'components/Wall/WallCanvasBackground';
import WallCanvasContent from 'components/Wall/WallCanvasContent';
import Matrix from 'components/Wall/Matrix';
import MatrixGrid from 'components/Wall/MatrixGrid';
import CenterSpacer from 'components/Wall/CenterSpacer';

const canvasWidth = '4320px';
const canvasHeight = '2000px';

function Wall({ toggleTouching }) {
  const [wallData, setWallData] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const detectMobile = useMobileDetect();

  const { width: screenWidth } = useWindowSize();
  const gridSize = screenWidth * 1;

  const refContainer = useRef();

  useEffect(() => {
    let baseUrl = 'https://back-of-the-wall.vercel.app';
    // process.env.NODE_ENV == 'production'
    //   ? 'https://back-of-the-wall.vercel.app'
    //   : 'http://localhost:8000';

    axios.get(`${baseUrl}/wall`).then(res => {
      console.log('response', res.data.wall);
      setWallData(res.data.wall);
    });
  }, []);

  return (
    <WallCanvas ref={refContainer}>
      <WallCanvasBackdrop
        key="canvas-backdrop"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />
      <WallCanvasBackground
        key="canvas-background"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.66 }}
      />
      <WallCanvasContent
        key="canvas-content"
        drag
        layout
        dragConstraints={refContainer}
        // The below do not work, but are here as
        // references for things that don't work //
        // * dragTransition={{ power: 0.03, bounceStiffness: 3, bounceDamping: 200 }}
        // * dragElastic={1}
        dragMomentum={true}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        onTouchStart={() => toggleTouching(true)}
        onTouchEnd={() => toggleTouching(false)}
      >
        <Matrix
          key="matrix"
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          scaleFactor={
            detectMobile.isMobile()
              ? screenWidth < 440
                ? screenWidth / 440
                : 1
              : 1
          }
        >
          <MatrixGrid
            key="matrix-nw"
            style={{ background: 'rgba(255, 0, 0, 0)' }}
            orientation={'NW'}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
          >
            {wallData &&
              wallData.self.map((post, index) => (
                <Post
                  key={`post-self-${index}`}
                  {...post}
                  isDragging={isDragging}
                />
              ))}
            <CenterSpacer />
          </MatrixGrid>
          <MatrixGrid
            key="matrix-ne"
            style={{ background: 'rgba(255, 255, 0, 0)' }}
            orientation={'NE'}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
          >
            <CenterSpacer />
            {wallData &&
              wallData.others.map((post, index) => (
                <Post
                  key={`post-others-${index}`}
                  {...post}
                  isDragging={isDragging}
                />
              ))}
          </MatrixGrid>
          <MatrixGrid
            key="matrix-sw"
            style={{ background: 'rgba(0, 255, 255, 0)' }}
            orientation={'SW'}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
          >
            {wallData &&
              wallData.career.map((post, index) => (
                <Post
                  key={`post-career-${index}`}
                  {...post}
                  isDragging={isDragging}
                />
              ))}
            <CenterSpacer />
          </MatrixGrid>
          <MatrixGrid
            key="matrix-se"
            style={{ background: 'rgba(255, 0, 255, 0)' }}
            orientation={'SE'}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
          >
            <CenterSpacer />
            {wallData &&
              wallData.daily.map((post, index) => (
                <Post
                  key={`post-daily-${index}`}
                  {...post}
                  isDragging={isDragging}
                />
              ))}
          </MatrixGrid>
        </Matrix>
        <Form
          key="form"
          scaleFactor={
            detectMobile.isMobile()
              ? screenWidth < 440
                ? screenWidth / 440
                : 1
              : 1
          }
          isMobile={detectMobile.isMobile()}
        />
      </WallCanvasContent>
    </WallCanvas>
  );
}

export default Wall;
