import styled, { css } from 'styled-components';
import { stylesNoSelect } from 'styles';
import { motion } from 'framer-motion';

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 88888888;

  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0 10vw;

  /*background: url(/background.jpg) no-repeat center center;
  background-size: cover;*/

  /*background-color: #7e7677;*/

  ${stylesNoSelect}

  ${({ hasClicked }) =>
    !hasClicked &&
    css`
      /*@media (max-width: 1350px) {
        flex-direction: column-reverse;
        padding-top: 10vw;
        overflow-y: auto;
      }*/
      @media (max-width: 1350px) {
        position: relative;
        flex-direction: column-reverse;
        padding: 0 16px 48px 16px;
        height: auto;
        /*overflow-y: auto;*/
      }
    `};
`;

export default Wrapper;
