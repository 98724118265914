import styled from 'styled-components';
import { motion } from 'framer-motion';

const WallCanvasContent = styled(motion.div)`
  position: relative;
  top: 0;
  left: 0;
  z-index: 99999;

  /*width: 2880px;
  height: 1620px;
  margin-top: calc((100vh - 1620px) / 2);
  margin-left: calc((100vw - 2880px) / 2);*/

  width: ${props => props.canvasWidth};
  height: ${props => props.canvasHeight};

  margin-top: calc((100vh - ${props => props.canvasHeight}) / 2);
  margin-left: calc((100vw - ${props => props.canvasWidth}) / 2);

  /*width: 200vw;
  height: 200vh;
  margin-top: calc((100vh - 200vh) / 2);
  margin-left: calc((100vw - 200vw) / 2);*/

  /*border: 1px solid red;*/
`;

export default WallCanvasContent;
