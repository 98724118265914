import styled from 'styled-components';

const CardForm = styled.form`
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 99999999;

	display: flex;
	flex-direction: column;
	max-width: calc(100vw - 32px);
	width: 520px;
	height: 320px;
	margin: 0;
	padding: 0 16px;

	background: white;

	border-radius: 24px;

	overflow: hidden;
	transform: translateX(-50%) translateY(-50%);

	box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.07);
`;

export default CardForm;
