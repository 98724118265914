import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';

import {
	CardFormBG1,
	CardFormBG2,
	CardFormBG3,
	CardFormBG4,
} from './CardFormBG';

import CardForm from './CardForm';
import FormDetails from './FormDetails';
import ContainerWords from './ContainerWords';
import Words from './Words';
import WordsGhost from './WordsGhost';
import Nickname from './Nickname';
import ButtonSubmit from './ButtonSubmit';

import AfterContent from './AfterContent';
import AfterContentText from './AfterContentText';
import AfterContentNickname from './AfterContentNickname';
import AfterContentButton from './AfterContentButton';

function Form({ scaleFactor, isMobile }) {
	const [nickname, setNickname] = useState('');
	const [words, setWords] = useState('');
	const [bgIndex, setBGIndex] = useState(0);
	const [wordsHeight, setWordsHeight] = useState(0);
	const [hasSubmitted, setHasSubmitted] = useState(false);

	const refWords = useRef();
	const refWordsGhost = useRef();

	const animateWords = useAnimation();
	const animateBG1 = useAnimation();
	const animateBG2 = useAnimation();
	const animateBG3 = useAnimation();
	const animateBG4 = useAnimation();

	const handleSubmit = e => {
		e.preventDefault();
		axios
			.post('https://back-of-the-wall.vercel.app/post', {
				nickname: nickname,
				words: words,
			})
			.then(res => {
				// console.log('after response', res);
				setHasSubmitted(true);
			});
	};

	useEffect(() => {
		animateBackground();
		handleFocus();
	}, []);

	useEffect(() => {
		animateBackground();
	}, [bgIndex]);

	useEffect(() => {
		if (words.length % 3 == 0) {
			let ghostBoundingBox = null;

			if (refWordsGhost && refWordsGhost.current) {
				ghostBoundingBox = refWordsGhost.current.getBoundingClientRect();

				// console.log('ghost', ghostBoundingBox);

				if (ghostBoundingBox.height != wordsHeight) {
					// console.log('updadte', ghostBoundingBox.height);
					setWordsHeight(ghostBoundingBox.height);
					animateWords.start({ height: ghostBoundingBox.height });
				}
			}
		}
	}, [words]);

	const animateBackground = () => {
		animateBG1.start({
			opacity: bgIndex == 0 ? 1 : 0,
			transition: { type: 'tween', duration: 3 },
		});
		animateBG2.start({
			opacity: bgIndex == 1 ? 1 : 0,
			transition: { type: 'tween', duration: 3 },
		});
		animateBG3.start({
			opacity: bgIndex == 2 ? 1 : 0,
			transition: { type: 'tween', duration: 3 },
		});
		animateBG4.start({
			opacity: bgIndex == 3 ? 1 : 0,
			transition: { type: 'tween', duration: 3 },
		});

		setTimeout(() => {
			let bgIndexCopy = bgIndex;
			bgIndexCopy++;
			setBGIndex(bgIndexCopy % 4);
		}, 3000);
		// setTimeout(() => animateBackground(), 1000);
	};

	const handleFocus = () => {
		// console.log('onHandleFocus', refWords, refWords.current);

		if (refWords && refWords.current) {
			refWords.current.focus();
		}
	};

	const handleReset = e => {
		e.preventDefault();
		setNickname('');
		setWords('');
		setWordsHeight(0);
		setHasSubmitted(false);
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.66 }}
		>
			<CardForm scaleFactor={scaleFactor} className="cardForm">
				<CardFormBG1
					initial={{ opacity: 1 }}
					animate={animateBG1}
					className="cardForm__background"
				/>
				<CardFormBG2
					initial={{ opacity: 0 }}
					animate={animateBG2}
					className="cardForm__background"
				/>
				<CardFormBG3
					initial={{ opacity: 0 }}
					animate={animateBG3}
					className="cardForm__background"
				/>
				<CardFormBG4
					initial={{ opacity: 0 }}
					animate={animateBG4}
					className="cardForm__background"
				/>
				<AnimatePresence>
					{!hasSubmitted && (
						<>
							<ContainerWords
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								onClick={handleFocus}
								className="cardForm__form"
							>
								<Words
									ref={refWords}
									animate={animateWords}
									// value={words}
									onChange={e => setWords(e.target.value)}
									contenteditable={true}
									placeholder={`주변의 시선을 신경쓰지 않는다면,
무엇을 하고 싶나요?`}
									className="cardForm__form__words"
								/>
								<WordsGhost ref={refWordsGhost}>{words}</WordsGhost>
							</ContainerWords>
							<FormDetails
								isActive={words && words.length > 16}
								className="cardForm__details"
							>
								<Nickname
									value={nickname}
									onChange={e => setNickname(e.target.value)}
									placeholder="닉네임"
									className="cardForm__details__input"
								/>
								<ButtonSubmit
									onClick={handleSubmit}
									className="cardForm__details__button"
									isMobile={isMobile}
								>
									붙이기
								</ButtonSubmit>
							</FormDetails>
						</>
					)}
				</AnimatePresence>
				<AnimatePresence>
					{hasSubmitted && (
						<AfterContent
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							// exit={{ opacity: 0 }}
						>
							<AfterContentText>{words}</AfterContentText>
							<AfterContentNickname>
								—{' '}
								{nickname && nickname.length > 1 ? nickname : '누군가의 속마음'}{' '}
								—
							</AfterContentNickname>
							<AfterContentButton onClick={handleReset}>
								더 쓰기
							</AfterContentButton>
						</AfterContent>
					)}
				</AnimatePresence>
			</CardForm>
		</motion.div>
	);
}

export default Form;
