import styled, { css } from 'styled-components';
import { transitionTween } from 'styles';

const ButtonSubmit = styled.button`
	flex: 1;
	width: auto;
	height: 48px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.66);
	color: #ffffff;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;

	${props =>
		!props.isMobile &&
		css`
			&:hover {
				background: rgba(0, 0, 0, 0.8);
			}
		`}

	${transitionTween}
`;

export default ButtonSubmit;
