import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const MatrixGrid = styled(motion.div)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /*width: 100vw;
  height: 100vh;*/
  /*width: 1440px;
  height: 810px;*/

  width: calc(${props => props.canvasWidth} / 2);
  height: calc(${props => props.canvasHeight} / 2);
  justify-content: flex-start;
  align-content: flex-start;

  ${props =>
    props.orientation == 'NW' &&
    css`
      flex-direction: column;
      justify-content: flex-end;
      align-content: flex-end;
    `}

    ${props =>
      props.orientation == 'NE' &&
      css`
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-content: flex-start;
      `}

    ${props =>
      props.orientation == 'SW' &&
      css`
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-content: flex-end;
      `}

    ${props =>
      props.orientation == 'SE' &&
      css`
        flex-direction: column;
      `}

    overflow: hidden;
`;

export default MatrixGrid;
