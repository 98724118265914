import styled from 'styled-components';

const AfterContentNickname = styled.p`
	height: 48px;
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-weight: 700;
	border-radius: 16px;
	background: transparent;
	color: rgba(0, 0, 0, 0.33);
	text-align: center;
	outline: none;
`;

export default AfterContentNickname;
