import styled from 'styled-components';
import { motion } from 'framer-motion';

const InvitationOpen = styled(motion.button)`
	position: absolute;
	top: 50%;
	left: 50%;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 134px;
	height: 48px;

	/*margin: 0 0 0 calc(134px / 2 * -1);*/
	margin: 0;
	padding: 4px 0 0 0;
	background: #3f3b43;
	color: #ffffff;

	font-size: 16px;
	line-height: 16px;
	font-weight: 700;

	border-radius: 30px;
	border: 0;
	box-shadow: 0px 8px 14px rgba(95, 85, 85, 0.2);

	/*transform: translateX(-50%) translateY(calc(-50% + 16px));*/
	transform-origin: 0 0;

	outline: none;
`;

export default InvitationOpen;
