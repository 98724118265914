import styled from 'styled-components';
import { motion } from 'framer-motion';

const InvitationCard = styled(motion.div)`
	position: absolute;
	top: 0;
	width: 100%;
	/*z-index: 99999;*/
	overflow: hidden;
	border-radius: 20px;
	transform-style: preserve-3d;
	backface-visibility: hidden;

	/*mix-blend-mode: luminosity;
	transform: translate3d(0, 0, 0);
	perspective: 1000px;
	-webkit-backface-visibility: visible;
	backface-visibility: visible;*/
`;

export default InvitationCard;
