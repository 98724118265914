import styled from 'styled-components';
import { motion } from 'framer-motion';

const ContainerWords = styled.div`
	position: relative;
	flex: 1;
	width: calc(100% + 32px);
	height: calc(100% - 68px);
	margin: 0 -16px;
	padding: 0;
	/*border: 1px solid rgba(0, 0, 0, 0.15);*/
	border-radius: 16px;
	background: transparent;
	text-align: center;
	outline: none;

	display: flex;
	align-items: center;
	/*border: 1px solid red;*/
`;

export default ContainerWords;
